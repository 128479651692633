<template>
  <div class="b-datepicker">
    <date-picker
      :id="id"
      :value="value"
      :type="type"
      @input="(val) => $emit('input', val)"
      value-type="timestamp"
      :placeholder="placeholder"
      :confirm="true"
      :disabled="disabled"
    >
      <template #icon-calendar>
        <CalendarIcon :class="calendarClass" />
      </template>
    </date-picker>
    <div
      v-if="error"
      class="text-red-500 mt-2 text-sm leading-4"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';
import CalendarIcon from '@/components/icons/calendar.icon';

export default {
  components: {
    DatePicker,
    CalendarIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Date, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'datetime',
    },
    error: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: '',
    },
    disabledDateFunc: {
      type: Function,
      default: (date) => {
        return dayjs().isAfter(date);
      },
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    calendarClass: {
      type: String,
      default: 'text-primary w-6 h-6',
    },
  },
};
</script>

<style lang="scss">
.b-datepicker {
  .mx-datepicker {
    @apply block;
    @apply w-full;
    .mx-input-wrapper {
      input {
        @apply opacity-0;
        @apply w-10 h-10;
      }
      .mx-icon-calendar {
        @apply absolute left-0 right-auto;
        @apply z-10;
        // @applt text-3xl;
        svg {
          @apply w-10 h-10;
        }
      }
    }
  }
}
</style>
